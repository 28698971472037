<script>
  export default {
    created() {
      // this.$store.reset();

      this.$store.dispatch('logout');
      localStorage.removeItem("phone");
      this.$cookies.remove("phone");
      this.$cookies.remove("classid");
      this.$router.push({ path: "/login" });
            
      this.$store.commit("logout");
      this.$store.commit('setLogoutState');
      localStorage.clear();
      localStorage.removeItem("id");
      localStorage.removeItem("grade");
      localStorage.removeItem("name");
      localStorage.removeItem("teacherid");
      localStorage.removeItem("role");
      localStorage.removeItem("classid");      
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userData");                 

      //  this.$store.commit('logout');
      this.$router.push("/login");
    },
  };
</script>
